.parallax-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  @include left(0);
  top: 0;
  z-index: 0;
  &.dots-wrap {
    z-index: 40
  }
  @include breakpoints-down(md) {
    display: none
  }
  .inner-parallax {
    height: 800px;
    width: 100%;
    position: absolute;
    display: block;
    & > div {
      height: 800px;
      width: 100%;
      display: block;
    }
    &.large {
      top: 10px;
    }
    &.medium {
      top: -130px;;
    }
    .Masthead {
      overflow: visible;
    }
    .parallax-figure {
      > div {
        display: block;
        position: absolute;
        top: 200px;
        width: 100%;
        height: 700px;
        svg {
          position: absolute
        }
      }
    }
  }
  .left-align {
    text-align: left;
    svg {
      left: -248px
    }
  }

  .right-align {
    text-align: right;
    svg {
      right: -248px
    }
  }

  .big {
    svg {
      top: -100px;
      transform: scale(0.7)
    }
  }

  .small {
    svg {
      transform: scale(0.5)
    }
  }

  .cloud {
    @include use-theme(opacity, 0.25, 0.12);
    @include use-theme(fill, $palette-primary-light, $palette-common-black);
    width: 550px;
    height: 250px;
    top: 0
  }

  .cloud-wrap {
    top: 200px;
    position: relative;
  }
}
