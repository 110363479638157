$error-deco: '../images/hosting/error-deco.png';

.error-wrap {
  width: 100%;
  height: 120vh;
  display: flex;
  align-items: center;
  .text {
    @include breakpoints-up(md) {
      @include padding-left($spacing5);
      @include border-left(1px solid);
      @include use-theme(border-color, $light-divider, $dark-divider)
    }
    @include breakpoints-down(sm) {
      text-align: center;
      margin: spacing(5, 0, 20);
    }
    h4 {
      font-weight: $font-bold;
      margin-bottom: $spacing4
    }
  }
  .button {
    margin-top: $spacing4
  }
  .flex {
    display: flex;
    justify-content: center
  }

  .deco {
    position: relative;
    @include breakpoints-down(sm) {
      margin: spacing(40, 0, 10)
    }
    h3 {
      color: $palette-common-white;
      font-size: 84px;
      text-transform: capitalize;
      font-weight: 700;
      padding-top: 40px;
      [dir="ltr"] {
        padding-left: $spacing2
      }
      [dir="rtl"] {
        padding-right: $spacing6
      }
      @include padding-left($spacing2);
      position: relative;
      z-index: 1
    }
    &:before {
      content: "";
      width: 320px;
      height: 230px;
      background: url($error-deco) no-repeat;
      position: absolute;
      background-size: 100%;
      @include left(-40px);
      @include breakpoints-up(sm) {
        top: $spacing3 * -1;
      }
      @include breakpoints-down(xs) {
        transform: scale(0.9);
        @include left(-48px)
      }
    }
  }
}

