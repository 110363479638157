$particle-bg: '/images/hosting/particle.png';

#pricing {
  .decoration {
    position: absolute;
    background: url($particle-bg) repeat;
    width: 100%;
    height: 100%;
  }

  .pricing-wrap {
    > section {
      position: relative;
      display: flex;
      @include breakpoints-down(md) {
        flex-wrap: wrap;
        justify-content: center;
      }
      > * {
        margin: spacing(3, 1);
        flex: 1;
        @include breakpoints-down(sm) {
          margin: spacing(2, 1)
        }
      }
    }
  }
}