#benefit {
  .root {
    position: relative;
    overflow: hidden;
    background-color: $palette-primary-dark;
    > div > img {
      opacity: 0.25
    }
  }

  .parallax-img {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    left: 0;
    top: 0;
    @include breakpoints-down(md) {
      background-image: url($imgAPI-hosting3);
      background-repeat: no-repeat;
      filter: blur(8px)
    }
    .parallax {
      filter: blur(6px);
      height: 670px;
      background: url($imgAPI-hosting3) no-repeat center center;
      background-size: cover;
    }
  }

  .wrapper {
    position: relative;
    padding-top: $spacing10;
    @include breakpoints-up(lg) {
      padding-left: $spacing8;
      padding-right: $spacing8;
    }
  }

  .desc {
    padding: spacing(0, 0, 8, 3);
    @include breakpoints-down(xs) {
      padding: spacing(0, 3, 8, 3);
      button {
        width: 100%
      }
    }
  }
  
  .btn {
    height: 44px;
    line-height: 44px;
    padding: 0 36px;
    @include breakpoints-down(xs) {
      width: 100%
    }
  }

  .list {
    display: block;
    list-style: disc;
    @include padding-left($spacing2);
    margin-bottom: $spacing5;
    color: $palette-common-white;
    @include breakpoints-up(sm) {
      font-size: 18px;
    }
    @include breakpoints-up(xs) {
      padding: 0;
    }
    li {
      line-height: 38px;
      list-style: disc;
    }
  }

  .img {
    margin: spacing(3, 0, 0, 0);
    overflow: hidden;
    @include breakpoints-down(md) {
      @include text-align(right);
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%
    }
    img {
      max-width: 100%;
      display: block;
      @include breakpoints-down(sm) {
        width: 100%
      }
    }
  }
}